<template>
    <div class="add-box">
        <i-header :title="$route.query.label" @back-event="toBack"></i-header>
        <div class="cnt-box">
            <component key="static" v-if="config['headline'] && config['headline'].length != 0" :is="$evalTemplate('Vnode', config['headline'].template)"></component>
            <i-field v-model="message" rows="4" autosize type="textarea" :placeholder="'请填写' + $route.query.label" />
            <i-button :loading="loading" @click="submit" :disabled="loading" loading-text="提交" block round type="primary" size="normal"> 提交 </i-button>
        </div>
    </div>
</template>

<script>
import generic from "../../utils/generic";

export default {
    name: "index",
    components: {},
    data() {
        return {
            title: "会议总结",
            loading: false,
            message: "",
            config: {}
        };
    },
    created() {
        this.init();
        //初始化
        this.message = this.$route.query.OldData;
    },
    methods: {
        init() {
            // let _conf = this.$store.state.summaryConfig || []
            let _conf = this.$smartStorage.get('summaryConfig') || []
            console.log("====会议总结配置", _conf);
            let context = this
            _conf.forEach((item) => {
                try {
                    if (item.expr && eval(item.expr)) {
                        this.config[item.code] = item
                    }
                } catch (error) {
                    console.log(error,item.expr);
                }
            })
        },
        toBack() {
            this.$router.back();
        },
        submit() {
            if (this.message) {
                console.log("====提交会议总结", this.message);
                let context = {
                    miceId: this.$route.query.miceId,
                    meta: {
                        [this.$route.query.controlName]: this.message.trim(),
                    },
                    message: this.message.trim()
                };
                let sign = true
                if(this.config['checkConfig']) {
                    let checkList = this.config['checkConfig']?.list || []
                    let checkExpr = checkList.filter((item) => {
                        return eval(item.expr)
                    })
                    if (checkExpr.length > 0) {
                        checkExpr.map((val) => {
                            val.rules.map((v) => {
                                if (eval(v.expr)) {
                                    this.$itoast.fail(v.errorMsg)
                                    sign = false
                                }
                            })
                        })
                    }
                }
                if (!sign) return false
                this.loading = true;

                let _config = generic.replaceEvalObject(this.config['create'], context);
                this.$service.GenericPost(_config).then((res) => {
                    this.loading = false;
                    if (res.success) {
                        this.$router.back();
                    } else {
                        this.$itoast.fail("订单错误,请联系管理员！");
                    }
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.add-box {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    .cnt-box {
        padding: 0 0.18rem;
        box-sizing: border-box;
        .tips {
            color: red;
            font-size: 0.12rem;
        }
        /deep/ .van-cell {
            background: #F5F5F5;
            border-radius: 0.04rem;
            margin-bottom: 0.3rem;
        }
        /deep/ .van-button__text {
            font-size: 0.16rem;
        }
    }
    .submit-btn {
        width: 88%;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 0.3rem;
        margin: 11.73vw auto 0 auto;
        font-size: 0.15rem;
        background: var(--themeColor);
        display: block;
        color: #fff;
        text-align: center;
    }
}
</style>
